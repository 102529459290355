import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useGetEndsOfScale, useGetProductCategories } from "../api/endpoints/product-categories";
import { ProductCategory } from "../api/types";

export const useProductCategories = () => {
  const getProductCategories = useGetProductCategories();

  return useSuspenseQuery({
    queryKey: ["productCategories"],
    queryFn: getProductCategories,
    select: (productCategories) => {
      return {
        productCategories,
        productCategoriesMap: Object.fromEntries(
          productCategories.map((category) => [category.id, category]),
        ),
      };
    },
  });
};

export const useEndsOfScale = ({ categoryId }: { categoryId?: ProductCategory["id"] }) => {
  const getEndsOfScale = useGetEndsOfScale();

  return useQuery({
    queryKey: ["productCategories", categoryId, "endsOfScale"],
    queryFn: () => (categoryId ? getEndsOfScale(categoryId) : []),
    enabled: !!categoryId,
  });
};
