import { AddOutlined, Commit, Inventory2Outlined, TokenOutlined } from "@mui/icons-material";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { DialogTrigger, MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PlantMaterial } from "../../../api/types";
import { Button } from "../../../components/Button";
import { DataGrid } from "../../../components/DataGrid";
import { Menu, MenuItem } from "../../../components/Menu";
import { PageContainer } from "../../../components/PageContainer";
import { StatusIndicator } from "../../../components/StatusIndicator";
import { TopBar } from "../../../components/TopBar";
import { Label, Label16 } from "../../../components/Typography";
import { useElementaries } from "../../../state/elementaries";
import { useMaterials } from "../../../state/materials";
import { useSuppliers } from "../../../state/suppliers";
import { UploadMaterialModal } from "./UploadMaterials";

export const MaterialsList = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useMaterials();

  const { data: suppliers } = useSuppliers();

  const input = useMemo(
    () => (
      <>
        <DialogTrigger>
          <Button intent="secondary">{t("Upload Materials")}</Button>
          <UploadMaterialModal />
        </DialogTrigger>

        <MenuTrigger>
          <Button>
            <AddOutlined />
            {t("New Material")}
          </Button>
          <Menu placement="bottom end">
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Material")}
              onAction={() => navigate("suppliers-and-materials/new-raw-material")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Packaging Material")}
              onAction={() => navigate("suppliers-and-materials/new-packaging")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add Own Product")}
              onAction={() => navigate("suppliers-and-materials/new-prechain-product")}
            />
          </Menu>
        </MenuTrigger>
      </>
    ),
    [navigate, t],
  );

  return (
    <PageContainer>
      <TopBar
        title={t("Materials")}
        subtitle={
          <>
            {rawMaterials.length} {t("materials", { count: rawMaterials.length })},{" "}
            {suppliers.length} {t("suppliers", { count: suppliers.length })}
          </>
        }
        icon={<TokenOutlined />}
        input={[...rawMaterials, ...packagingMaterials].length > 0 ? input : undefined}
      />
      <div className="flex-grow flex flex-col gap-4 py-8">
        {[...rawMaterials, ...packagingMaterials].length === 0 && (
          <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
            <div className="flex flex-col items-center gap-3">
              <Label className="flex items-center gap-2">{t("No materials added yet")}</Label>
              <div className="flex items-center gap-5">{input}</div>
            </div>
          </div>
        )}
        {rawMaterials.length > 0 && (
          <MaterialDataGrid inputs={rawMaterials} header={t("Material")} type="rawMaterials" />
        )}
        {packagingMaterials.length > 0 && (
          <MaterialDataGrid inputs={packagingMaterials} header={t("Packaging")} type="packaging" />
        )}
      </div>
    </PageContainer>
  );
};

const MaterialDataGrid = ({
  inputs: materials,
  header,
  type,
}: {
  inputs: PlantMaterial[];
  header: string;
  type: "rawMaterials" | "packaging";
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { elementaries } = useElementaries();

  const getRow = (input: PlantMaterial) => {
    if (input.supplier_product) {
      const item = input.supplier_product;
      return {
        id: item.id,
        category: elementaries.find((c) => c.id === item.elementary_id)!.name_en,
        productName: item.name,
        supplierCompany: item.supplier_plant?.supplier_company.name,
        plant: item.supplier_plant?.name,
        epdLinked: !!item.epd_id,
        isPrechainProduct: false,
      };
    }

    if (!input.prechain_product) {
      throw new Error("Invalid input");
    }

    const item = input.prechain_product;
    return {
      id: item.id,
      category: elementaries.find((c) => c.id === item.product.elementary_id)!.name_en,
      productName: item.product.name,
      supplierCompany: item.product.manufacturer.name,
      plant: item.plant_name,
      epdLinked: false,
      isPrechainProduct: true,
    };
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "category",
        headerName: header,
        width: 300,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-2 h-full">
            {type === "rawMaterials" && <TokenOutlined />}
            {type === "packaging" && <Inventory2Outlined />}
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "productName",
        headerName: t("Name"),
        width: 300,
      },
      {
        field: "supplierCompany",
        headerName: t("Supplier"),
        width: 250,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "plant",
        headerName: t("Supplier plant"),
        width: 200,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "epdLinked",
        headerName: t("EPD linked"),
        width: 200,
        align: "center",
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center">
            {row.isPrechainProduct ? (
              <div className="flex items-center gap-1">
                <Commit fontSize="small" className="-translate-x-0.5" />
                <Label16>{t("Own Product")}</Label16>
              </div>
            ) : (
              <StatusIndicator
                text={value ? "EPD" : t("Secondary Data")}
                done={!!value}
                missing={!value}
              />
            )}
          </div>
        ),
      },
    ];
  }, [t, header, type]);

  return (
    <>
      <div className="flex flex-col gap-1">
        <DataGrid
          rows={materials
            .map(getRow)
            .sort(
              (a, b) =>
                a.category.localeCompare(b.category) || a.productName.localeCompare(b.productName),
            )}
          columns={columns}
          onRowClick={(params: GridRowParams<{ id: string; isPrechainProduct: boolean }>) => {
            if (params.row.isPrechainProduct) {
              navigate(`/suppliers-and-materials/edit-prechain-product/${params.row.id}`);
            } else {
              navigate(`/suppliers-and-materials/edit/${params.row.id}`);
            }
          }}
        />
      </div>
    </>
  );
};
