import { Search, WindowOutlined } from "@mui/icons-material";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { TextField } from "../components/TextField";
import { TopBar } from "../components/TopBar";
import { DocumentsSidePanel } from "../page-components/products/DocumentsSidePanel";
import { EmptyProductList } from "../page-components/products/EmptyProductList";
import { MultiSelectActionBar } from "../page-components/products/MultiSelectActionBar";
import { ProductsGridView } from "../page-components/products/ProductsGridView";
import { TopBarInput } from "../page-components/products/TopBarInput";
import { useProductSelection } from "../page-components/products/useProductSelection";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";
import { useActivePlantProducts } from "../state/products";
import { ProductsTileView } from "./ProductsTileView";

export const Products = () => {
  const activePlant = useActivePlant();

  const { t } = useTranslation();

  const { data: products } = useActivePlantProducts();
  const count = products.length;

  const {
    isSelectMode,
    selectedProducts,
    toggleSelect,
    toggleSelectMode,
    toggleSelectAll,
    resetSelectedProducts,
  } = useProductSelection({ products });

  const [activeTab, setActiveTab] = useLocalStorage("products-view-mode", "grid");

  useEffect(
    function resetSelectionOnPlantChange() {
      resetSelectedProducts();
    },
    [activePlant, resetSelectedProducts],
  );

  const navigate = useNavigate();
  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 3) {
      navigate("/onboarding");
    }
  }, [navigate, onboardingStep]);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = useCallback(() => setSidePanelOpen((prev) => !prev), []);

  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");
  useDebounce(
    () => {
      setDebouncedSearchInput(searchInput);
    },
    200,
    [searchInput],
  );

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={t("Product overview")}
        subtitle={
          <>
            {count} {t("products", { count })}
          </>
        }
        input={
          count > 0 ? (
            <TopBarInput
              selectedProducts={selectedProducts}
              isSelectMode={isSelectMode}
              onCreateDocuments={toggleSidePanel}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : undefined
        }
      />
      <div className="flex-grow flex flex-col gap-4 py-8">
        <Suspense fallback={<Loading />}>
          {count > 0 && (
            <>
              <DocumentsSidePanel
                selectedProducts={selectedProducts}
                open={sidePanelOpen}
                onClose={() => setSidePanelOpen(false)}
              />
              {activeTab === "grid" ? (
                <>
                  <MultiSelectActionBar
                    products={products}
                    selectedProducts={selectedProducts}
                    isSelectMode={isSelectMode}
                    toggleSelectMode={toggleSelectMode}
                    toggleSelectAll={toggleSelectAll}
                    resetSelectedProducts={resetSelectedProducts}
                  />
                  <ProductsTileView
                    products={products}
                    isSelectMode={isSelectMode}
                    selectedProducts={selectedProducts}
                    toggleSelect={toggleSelect}
                  />
                </>
              ) : (
                <div className="w-full grid items-end gap-6">
                  <div className="flex justify-end">
                    <TextField
                      value={searchInput}
                      onChange={(value) => setSearchInput(value)}
                      inputProps={{
                        addonLeft: <Search />,
                      }}
                    />
                  </div>
                  <ProductsGridView products={products} searchInput={debouncedSearchInput} />
                </div>
              )}
            </>
          )}
          {count === 0 && <EmptyProductList />}
        </Suspense>
      </div>
    </PageContainer>
  );
};
