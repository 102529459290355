import { WindowOutlined } from "@mui/icons-material";
import { GridColDef, GridFilterModel, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { DataGrid } from "../../components/DataGrid";
import { Label16 } from "../../components/Typography";
import { useProductCategories } from "../../state/productCategories";
import { ProductStatusIndicator, useAllProductStatusses } from "./ProductStatusIndicator";
import { useGetProductLink } from "./useProductLink";

export const ProductsGridView = ({
  products,
  searchInput,
}: {
  products: Product[];
  searchInput?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getProductLink = useGetProductLink();

  const columnVisibilityModel = useMemo(
    () => ({
      name: true,
      category: true,
      unit: false,
      created_at: true,
      status: true,
      link: false,
    }),
    [],
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  });

  useEffect(() => {
    setFilterModel((value) => ({
      ...value,
      quickFilterValues: searchInput?.split(" ") ?? [],
    }));
  }, [searchInput]);

  const getRow = (product: Product) => ({
    id: product.id,
    name: product.name,
    category: product.category.name.en,
    status: product.status,
    unit: product.unit,
    lcia_results_timestamp: product.lcia_results_timestamp,
    created_at: new Date(product.created_at),
  });

  const formatDate = (date: Date) => {
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const {
    data: { productCategories },
  } = useProductCategories();
  const allProductStatusses = useAllProductStatusses();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name"),
        width: 400,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center gap-2">
            <WindowOutlined />
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "category",
        headerName: t("Material"),
        width: 250,
        filterable: true,
        type: "singleSelect",
        valueOptions: productCategories.map((cat) => cat.name.en),
      },
      {
        field: "unit",
        headerName: t("Unit"),
        width: 120,
      },
      {
        field: "created_at",
        headerName: t("Date added"),
        width: 150,
        renderCell: ({ value }) => (value ? formatDate(value) : "-"),
        filterable: true,
        type: "date",
      },
      {
        field: "status",
        headerName: t("Status"),
        width: 240,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center">
            <ProductStatusIndicator status={value} />
          </div>
        ),
        filterable: true,
        type: "singleSelect",
        getOptionLabel: (value) => allProductStatusses[value as Product["status"]],
        valueOptions: Object.keys(allProductStatusses),
      },
      {
        field: "link",
      },
    ],
    [t, productCategories, allProductStatusses],
  );

  return (
    <DataGrid
      rows={products.map(getRow).sort((a, b) => a.name.localeCompare(b.name))}
      columns={columns}
      onRowClick={(params: GridRowParams) => {
        const product = products.find((p) => p.id === params.row.id);
        if (product) {
          navigate(getProductLink({ product }));
        }
      }}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      initialState={{
        columns: {
          columnVisibilityModel,
        },
      }}
      disableColumnFilter={false}
    />
  );
};
