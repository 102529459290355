import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";

import { ReactNode } from "react";
import { RouterProvider as ReactAriaRouterProvider } from "react-aria-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Auth0ProviderWithNavigate } from "./auth/Auth0ProviderWithNavigate";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { RootLoading } from "./components/RootLoading";
import { useLanguageSwitch } from "./localization/useLanguageSwitch";
import { ManageSuppliersAndMaterials } from "./page-components/company-settings/ManageSuppliersAndMaterials";
import { NewProductionProcess } from "./page-components/plant-settings/NewProductionProcess";
import { AddPlant } from "./pages/AddPlant";
import { CompanySettings } from "./pages/CompanySettings";
import { EditPlant } from "./pages/EditPlant";
import { EditProductionProcess } from "./pages/EditProductionProcess";
import { Index } from "./pages/Index";
import { Logout } from "./pages/Logout";
import { Onboarding } from "./pages/Onboarding";
import { ProductComparison } from "./pages/ProductComparison";
import { ProductDetailPage } from "./pages/ProductDetailPage";
import { Production } from "./pages/Production";
import { Products } from "./pages/Products";
import { Search } from "./pages/Search";
import { UserProfile } from "./pages/UserProfile";
import { Edit } from "./pages/edit/Edit";
import { ProductDetails } from "./pages/edit/ProductDetails";
import { ProductProductionProcess } from "./pages/edit/ProductProductionProcess";
import { ProductRecipe } from "./pages/edit/ProductRecipe";
import { ProductSpecs } from "./pages/edit/ProductSpecs";
import { NotFoundPage } from "./pages/special/NotFoundPage";
import { AdminModeProvider } from "./state/admin";
import { BannerProvider } from "./state/banner";
import { NavigationProvider } from "./state/navigation";
import { SplashProvider } from "./state/splash";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

const AppStateProviders = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  useLanguageSwitch();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactAriaRouterProvider navigate={navigate}>
        <BannerProvider>
          <SplashProvider>
            <NavigationProvider>
              <AdminModeProvider>
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              </AdminModeProvider>
            </NavigationProvider>
          </SplashProvider>
        </BannerProvider>
      </ReactAriaRouterProvider>
    </QueryClientProvider>
  );
};

const Layout = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <RootLoading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({});
    return;
  }

  return (
    <AppStateProviders>
      <div className="flex flex-col items-start w-full h-screen">
        <Outlet />
      </div>
    </AppStateProviders>
  );
};

const AuthLayout = () => (
  <Auth0ProviderWithNavigate>
    <Layout />
  </Auth0ProviderWithNavigate>
);

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        element: <Logout />,
        path: "/logout",
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <Index />,
            path: "/",
            children: [
              {
                path: "/",
                element: <Products />,
              },
              {
                path: "/onboarding",
                element: <Onboarding />,
              },
              {
                path: "/products",
                element: <Products />,
              },
              {
                path: "/edit",
                element: <Edit />,
                children: [
                  {
                    path: "/edit/product-details",
                    element: <ProductDetails />,
                  },
                  {
                    path: "/edit/product-specs",
                    element: <ProductSpecs />,
                  },
                  {
                    path: "/edit/product-production-process",
                    element: <ProductProductionProcess />,
                  },
                  {
                    path: "/edit/product-recipe",
                    element: <ProductRecipe />,
                  },
                ],
              },
              {
                path: "/products/:id",
                element: <ProductDetailPage />,
              },
              {
                path: "/company-settings",
                element: <CompanySettings />,
              },
              {
                path: "/production",
                element: <Production />,
              },
              {
                path: "/production/processes/new",
                element: <NewProductionProcess />,
              },
              {
                path: "/production/processes/:id/edit",
                element: <EditProductionProcess />,
              },
              {
                path: "/manage-plants/edit",
                element: <EditPlant />,
              },
              {
                path: "/manage-plants/new",
                element: <AddPlant />,
              },
              {
                path: "/suppliers-and-materials/*",
                element: <ManageSuppliersAndMaterials />,
              },
              {
                path: "/search",
                element: <Search />,
              },
              {
                path: "/profile",
                element: <UserProfile />,
              },
              {
                path: "/products/:id/compare/:otherId",
                element: <ProductComparison />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={router} />;
};
