import { CloseOutlined } from "@mui/icons-material";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { useProduct } from "../state/products";

import { Button } from "../components/Button";
import { ComparisonDocumentsSidePanel } from "../page-components/product-comparison/ComparisonDocumentsSidePanel";
import { ProductComparisonEnvironmentalPerformanceIndicators } from "../page-components/product-comparison/ProductComparisonEnvironmentalPerformanceIndicators";
import { ProductComparisonGWPChart } from "../page-components/product-comparison/ProductComparisonGWPChart";
import { ProductComparisonLifeCycleChart } from "../page-components/product-comparison/ProductComparisonLifeCycleChart";
import { ProductComparisonSummary } from "../page-components/product-comparison/ProductComparisonSummary";

export const ProductComparison = () => {
  return (
    <Suspense fallback={<Loading lazy />}>
      <ProductComparisonWrapper />
    </Suspense>
  );
};

const ProductComparisonWrapper = () => {
  const { id, otherId } = useParams<{ id: string; otherId: string }>();

  if (!id || !otherId) {
    return <div>Invalid product IDs for comparison</div>;
  }

  return <ProductComparisonContent id={id} otherId={otherId} />;
};

const ProductComparisonContent = ({ id, otherId }: { id: string; otherId: string }) => {
  const { t } = useTranslation();
  const { data: product1 } = useProduct(id);
  const { data: product2 } = useProduct(otherId);
  const navigate = useNavigate();
  const [isDocumentsPanelOpen, setIsDocumentsPanelOpen] = useState(false);

  if (!product1 || !product2) {
    return <Loading />;
  }
  return (
    <PageContainer>
      <TopBar
        icon={
          <Button intent="secondary" square onPress={() => navigate(`/products`)}>
            <CloseOutlined />
          </Button>
        }
        title={
          <div className="flex items-center gap-4">
            <span className="text-builtgreen">{product1.name}</span>|
            <span className="text-neutral-400">{product2.name}</span>
          </div>
        }
        subtitle={t("Product comparison")}
        variant="concrete"
        input={
          <div className="flex items-center gap-4">
            <Button intent="primary" onPress={() => setIsDocumentsPanelOpen(true)}>
              {t("Documents")}
            </Button>
          </div>
        }
      />
      <ComparisonDocumentsSidePanel
        product1={product1}
        product2={product2}
        open={isDocumentsPanelOpen}
        onClose={() => setIsDocumentsPanelOpen(false)}
      />
      <div className="flex flex-col gap-8 -mx-8 pb-20">
        <ProductComparisonSummary product1={product1} product2={product2} />
        <div className="flex flex-col gap-8 px-8">
          <ProductComparisonGWPChart product1={product1} product2={product2} />
          <ProductComparisonLifeCycleChart product1={product1} product2={product2} />
          <ProductComparisonEnvironmentalPerformanceIndicators
            product1={product1}
            product2={product2}
          />
        </div>
      </div>
    </PageContainer>
  );
};
