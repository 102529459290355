import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMemo } from "react";
import { FileTrigger } from "react-aria-components";
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalHeader } from "../../components/Modal";
import { Text16 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useActivePlant } from "../../state/plants";
import { useDownloadProductsBulkUploadSheet, useProductsBulkUpload } from "../../state/products";

export const UploadMaterialModal = () => {
  const { t } = useTranslation();
  const { mutate: upload, isPending: uploadLoading } = useProductsBulkUpload();
  const { mutate: download, isPending: downloadLoading } = useDownloadProductsBulkUploadSheet();

  const methods = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = ({ elementary_id }) => {
    download(elementary_id);
  };

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Upload products")} onClose={close} />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-4">
                    <Text16>
                      {t("Specify for which material you want to generate a bulk upload sheet.")}
                    </Text16>
                    <MaterialForm />
                  </div>
                  <div className="flex flex-col gap-4">
                    <Text16>
                      {t("Download an Excel file that specifies the expected format.")}
                    </Text16>
                    <Button intent="secondary" type="submit">
                      {downloadLoading ? <CircularProgress size="24px" /> : <DownloadOutlined />}
                      {t("Download sheet")}
                    </Button>
                  </div>
                  <div className="flex flex-col gap-4">
                    {" "}
                    <Text16>{t("Make changes to that file and upload it back here.")}</Text16>
                    <FileTrigger onSelect={upload}>
                      <Button intent="secondary">
                        {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
                        {t("Upload filled sheet")}
                      </Button>
                    </FileTrigger>
                  </div>
                </div>
              </form>
            </FormProvider>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

interface Form {
  categoryName: string;
  elementary_id: string;
}

const MaterialForm = () => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();
  const { elementaries } = useElementaries();

  const { watch } = useFormContext<Form>();
  const category = watch("categoryName");

  const plantProducesElementaries = useMemo(() => {
    return elementaries.filter(
      (e) => e.active && activePlant.product_categories.includes(e.product_category_id),
    );
  }, [elementaries, activePlant]);

  const categoryOptions = useMemo(() => {
    const uniqueOptions = new Set(plantProducesElementaries.map((e) => e.category));
    return Array.from(uniqueOptions)
      .sort((a, b) => a.localeCompare(b))
      .map((category) => ({
        id: category,
        label: category,
      }));
  }, [plantProducesElementaries]);

  const materialOptions = useMemo(() => {
    return elementaries
      .filter(
        (e) =>
          e.active &&
          e.category === category &&
          plantProducesElementaries.some((x) => x.id === e.id),
      )
      .map((e) => ({
        id: e.id,
        label: e.name_en,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [elementaries, category, plantProducesElementaries]);

  return (
    <div className="grid grid-cols-2 gap-8">
      <ComboBoxFieldConnected
        name="categoryName"
        options={categoryOptions}
        label={t("Category")}
        isRequired
      />
      <ComboBoxFieldConnected
        name="elementary_id"
        label={t("Material")}
        isRequired
        options={materialOptions}
        isDisabled={!category}
      />
    </div>
  );
};
