import { ImageOutlined, UploadFileOutlined } from "@mui/icons-material";
import { Button as ButtonPrimitive, FileTrigger } from "react-aria-components";
import { Product } from "../../api/types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { useImage, useUploadImage } from "../../api/endpoints/manufacturers";
import { apiLink } from "../../api/useApi";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useUpdateProduct } from "../../state/products";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";

const SxButton = tw(ButtonPrimitive)`
  w-full
  h-full
  z-20
  absolute
  bg-neutral-900
  bg-opacity-40
  top-0
  left-0
  opacity-0
  group-hover:opacity-100
  transition-opacity
  cursor-pointer
  text-white
  flex
  items-center
  justify-center
`;

export const ProductImage = ({
  product,
  disableUpload = false,
}: {
  product: Product;
  disableUpload?: boolean;
}) => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const uploadImage = useUploadImage();
  const { mutate: updateProduct } = useUpdateProduct();

  const getImageAccessLink = useImage();
  const validateFileSize = useValidateFileSize();

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleFileDrop = async (files: FileList | null) => {
    const file = files?.[0];

    if (!file) return;

    try {
      validateFileSize(file);
    } catch (_) {
      return;
    }

    try {
      const key = await uploadImage({
        manufacturerId: activeManufacturer.id,
        file,
      });
      updateProduct({
        productId: product.id,
        product: { ...product, image_url: key },
      });
    } catch (_) {
      showErrorToast(t("Couldn't upload product image."));
    }
  };

  const defaultImage = apiLink(`/static${product.category.image_url}`);
  useEffect(() => {
    const getImageSrc = async () => {
      if (!product?.image_url) {
        setImageSrc(defaultImage);
      } else {
        try {
          const img = await getImageAccessLink({
            manufacturerId: activeManufacturer.id,
            path: product.image_url,
          });
          setImageSrc(img);
        } catch (error) {
          console.error("Error generating image url:", error);
        }
      }
    };
    getImageSrc();
  }, [activeManufacturer, activePlant, product, getImageAccessLink, defaultImage]);

  return (
    <div className="shrink-0 w-[200px] h-[200px] p-[2px] bg-white relative group">
      <div className="absolute w-full h-full flex items-center justify-center">
        <ImageOutlined className="text-neutral-300" fontSize="large" />
      </div>
      {imageSrc && (
        <img className="w-full h-full relative z-10 object-cover" src={imageSrc} alt="Product" />
      )}
      {!disableUpload && (
        <FileTrigger onSelect={handleFileDrop}>
          <SxButton>
            <UploadFileOutlined fontSize="large" />
          </SxButton>
        </FileTrigger>
      )}
    </div>
  );
};
