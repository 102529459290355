import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Modal, ModalBody, ModalHeader } from "../../../components/Modal";
import { Text16 } from "../../../components/Typography";
import {
  useDownloadMaterialsBulkUploadSheet,
  useMaterialBulkUpload,
} from "../../../state/materials";

export const UploadMaterialModal = () => {
  const { t } = useTranslation();
  const { mutate: upload, isPending: uploadLoading } = useMaterialBulkUpload();
  const { mutate: download, isPending: downloadLoading } = useDownloadMaterialsBulkUploadSheet();

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Upload supplier materials")} onClose={close} />
          <ModalBody>
            <div className="flex flex-col gap-4">
              <Text16>
                {t("First, download an Excel file that specifies the expected format.")}
              </Text16>
              <Button intent="secondary" onPress={() => download()}>
                {downloadLoading ? <CircularProgress size="24px" /> : <DownloadOutlined />}
                {t("Download sheet")}
              </Button>
              <Text16>{t("Make changes to that file and upload it back here.")}</Text16>
              <FileTrigger onSelect={upload}>
                <Button intent="secondary">
                  {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
                  {t("Upload filled sheet")}
                </Button>
              </FileTrigger>
            </div>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};
