import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { Radio } from "../../components/RadioGroupField";
import { SidePanel } from "../../components/SidePanel";
import { Text16 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { RadioGroupFieldConnected } from "../../form-components/RadioGroupFieldConnected";
import { areProductsComparable } from "../../lib/product";
import { useActivePlant, usePlants } from "../../state/plants";
import { useProducts } from "../../state/products";

type Fields = {
  plant: string | null;
  product: string | null;
};

export const CompareSidePanel = ({
  product,
  open,
  onClose,
}: {
  product: Product;
  open: boolean;
  onClose: () => void;
}) => {
  const { data: plants } = usePlants();
  const activePlant = useActivePlant();

  const { t } = useTranslation();

  const methods = useForm<Fields>({
    defaultValues: {
      plant: activePlant.id,
      product: null,
    },
  });

  const plantOptions = useMemo(
    () => plants?.map((plant) => ({ label: plant.name, value: plant.id, id: plant.id })),
    [plants],
  );

  const selectedPlant = methods.watch("plant");
  useEffect(() => {
    methods.setValue("product", null);
  }, [selectedPlant, methods]);

  const { data: products } = useProducts({
    plantId: selectedPlant || activePlant.id,
  });

  const productOptions = useMemo(
    () =>
      products
        ?.filter((p) => areProductsComparable(p, product))
        .map((p) => ({ label: p.name, value: p.id, id: p.id })) || [],
    [products, product],
  );

  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Fields> = (data) => {
    navigate(`/products/${product.id}/compare/${data.product}`);
  };

  const isDisabled = productOptions.length === 0;

  return (
    <SidePanel open={open} onClose={onClose} title="Compare">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate className="flex flex-col gap-8">
          <ComboBoxFieldConnected name="plant" label={t("Plant")} options={plantOptions} />
          <div className="space-y-4">
            <Text16>{t("Select a product within the same category and declaration unit")}</Text16>
            {productOptions.length > 0 && (
              <RadioGroupFieldConnected
                name="product"
                isRequired
                aria-label={t("Select a product")}
              >
                {productOptions.map((option) => (
                  <Radio key={option.id} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </RadioGroupFieldConnected>
            )}
            {isDisabled && (
              <div className="flex justify-center">
                <Alert intent="info">
                  {t(
                    "No products within the same category and declaration unit in the {{plant}} plant.",
                    {
                      plant: selectedPlant
                        ? plantOptions?.find((p) => p.id === selectedPlant)?.label
                        : activePlant.name,
                    },
                  )}
                </Alert>
              </div>
            )}
          </div>

          <div>
            <Button type="submit" isDisabled={isDisabled}>
              {t("Compare products")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
