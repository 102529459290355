import type { Config } from "tailwindcss";
import animate from "tailwindcss-animate";

export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      fontFamily: {
        sans: ["CircularStd", "sans-serif"],
      },
      colors: {
        energyyellow: "#DCFF1D",
        steelblue: "#3A41FF",
        builtgreen: "#404D3E",
        concrete: "#E5E5DF",
        co2brown: "#DBD6B9",
        co2light: "#EBE8D3",
        co2dark: "#C1BA94",
      },
      backgroundImage: {
        texture: "url('/texture.png')",
      },
      boxShadow: {
        // Taken from design:
        "e-xs": "0px 1px 2px 0px #0000001A",
        "e-sm": "0px 1px 2px 0px #0000001A, 0px 3px 3px 0px #00000017",
        "e-md":
          "0px 1px 2px 0px #0000001A, 0px 3px 3px 0px #00000017, 0px 8px 5px 0px #0000000D, 0px 14px 6px 0px #00000003, 0px 22px 6px 0px #00000000",
      },
    },
  },
  plugins: [animate],
} satisfies Config;
