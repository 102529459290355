import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { StatusIndicator } from "../../components/StatusIndicator";

export const useAllProductStatusses = (): Record<Product["status"], string> => {
  const { t } = useTranslation();

  return {
    incomplete: t("Data missing"),
    draft: t("Ready for verification"),
    "under-review": t("Awaiting verification"),
    completed: t("EPD published & verified"),
  };
};

export const useProductStatus = (status: Product["status"]) => {
  const allStatuses = useAllProductStatusses();

  return allStatuses[status];
};

export const ProductStatusIndicator = ({ status }: { status: Product["status"] }) => {
  return (
    <StatusIndicator
      small
      text={useProductStatus(status)}
      missing={status === "incomplete"}
      waiting={status === "under-review"}
      ready={status === "draft"}
      done={status === "completed"}
    />
  );
};
