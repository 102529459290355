import tw from "tailwind-styled-components";
import { Product } from "../api/types";
import { ProductCard } from "../page-components/products/ProductCard";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
`;

export const ProductsTileView = ({
  products,
  isSelectMode,
  selectedProducts,
  toggleSelect,
}: {
  products: Product[];
  isSelectMode: boolean;
  selectedProducts: Set<Product>;
  toggleSelect: (product: Product) => void;
}) => (
  <Content>
    {products
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => (
        <ProductCard
          key={item.id}
          item={item}
          isSelectMode={isSelectMode}
          isSelected={selectedProducts.has(item)}
          onToggleSelect={() => toggleSelect(item)}
        />
      ))}
  </Content>
);
