import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteProduct,
  useGetProduct,
  useGetProductExcelExport,
  useGetProducts,
  useGetProductsBulkUploadSheet,
  usePatchProduct,
  usePostDocumentBulkPCF,
  usePostDocumentComparisonPCF,
  usePostProduct,
  usePostProductsBulkUpload,
  usePostUpdateProductLca,
  usePostVerifyProduct,
} from "../api/endpoints/products";
import { showErrorToast, showSuccessToast } from "../util/toasts";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

export const useProducts = ({ plantId }: { plantId?: string }) => {
  const { activeManufacturer } = useActiveManufacturer();
  const getProducts = useGetProducts();

  return useQuery({
    queryKey: [activeManufacturer.id, plantId, "products"],
    queryFn: () => getProducts({ manufacturerId: activeManufacturer.id, plantId: plantId! }),
    enabled: !!plantId,
  });
};

export const useActivePlantProducts = () => {
  const activePlant = useActivePlant();
  const { activeManufacturer } = useActiveManufacturer();

  const getProducts = useGetProducts();

  return useSuspenseQuery({
    queryKey: [activeManufacturer.id, activePlant.id, "products"],
    queryFn: () =>
      getProducts({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
      }),
  });
};

export const useSelectedProductId = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("selectedProduct") || undefined;

  return id;
};

export const useProduct = (id: string) => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const getProduct = useGetProduct();

  return useSuspenseQuery({
    queryKey: [activeManufacturer.id, activePlant.id, "products", id],
    queryFn: () =>
      getProduct({
        id,
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
      }),
  });
};

export const useAddProduct = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const postProduct = usePostProduct();

  return useMutation({
    mutationFn: (product: Parameters<typeof postProduct>[0]["product"]) =>
      postProduct({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        product,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "products"],
      });
    },
  });
};

export const useUpdateProduct = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const patchProduct = usePatchProduct();

  return useMutation({
    mutationFn: ({
      productId,
      product,
    }: Pick<Parameters<typeof patchProduct>[0], "productId" | "product">) =>
      patchProduct({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        productId,
        product,
      }),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "products", id],
      });
    },
  });
};

export const useRemoveProducts = () => {
  const queryClient = useQueryClient();
  const deleteProduct = useDeleteProduct();

  return useMutation({
    mutationFn: (props: Parameters<typeof deleteProduct>[0][]) => {
      return Promise.all(props.map((p) => deleteProduct(p)));
    },
    onSuccess: (_, [{ manufacturerId, plantId }]) => {
      queryClient.invalidateQueries({ queryKey: [manufacturerId, plantId, "products"] });
    },
  });
};

export const useUpdateProductLca = () => {
  const queryClient = useQueryClient();
  const updateProductLca = usePostUpdateProductLca();

  return useMutation({
    retry: false,
    mutationFn: updateProductLca,
    onSuccess: (_, { manufacturerId, plantId, productId }) => {
      queryClient.invalidateQueries({ queryKey: [manufacturerId, plantId, "products", productId] });
    },
  });
};

export const useDownloadProductExcelExport = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const getProductExcelExport = useGetProductExcelExport();

  return useMutation({
    mutationFn: async () => {
      const response = await getProductExcelExport({ manufacturerId: activeManufacturer.id });

      const downloadUrl = window.URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Product-Export.xlsx";
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },
  });
};

export const useDownloadProductsBulkUploadSheet = () => {
  const activePlant = useActivePlant();
  const getProductsBulkUploadSheet = useGetProductsBulkUploadSheet();

  return useMutation({
    mutationFn: async (elementaryId: string) => {
      const response = await getProductsBulkUploadSheet({ plantId: activePlant.id, elementaryId });

      const downloadUrl = window.URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Product-Bulk-Upload.xlsx";
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },
  });
};

export const useProductsBulkUpload = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const bulkUploadProducts = usePostProductsBulkUpload();

  return useMutation({
    mutationFn: (files: FileList | null) => {
      if (!files?.[0]) {
        throw new Error("Invalid file");
      }

      return bulkUploadProducts({
        manufacturerId: activeManufacturer.id,
        file: files[0],
      });
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "products"],
      });

      if (result.errors.length > 0) {
        showErrorToast(
          `Failed: ${result.failed}. \n\n${result.errors.map((e) => e.message).join("\n")}`,
        );
      } else {
        showSuccessToast(
          `Products uploaded successfully. Imported: ${result.imported}, Updated: ${result.updated}, Skipped: ${result.skipped}`,
        );
      }
    },
  });
};

export const useVerifyProduct = () => {
  const queryClient = useQueryClient();
  const verifyProduct = usePostVerifyProduct();

  return useMutation({
    mutationFn: verifyProduct,
    onSuccess: (_, { manufacturerId, plantId, productId }) => {
      queryClient.invalidateQueries({ queryKey: [manufacturerId, plantId, "products", productId] });
      queryClient.invalidateQueries({
        queryKey: [manufacturerId, plantId, productId, "declarations"],
      });
    },
  });
};

export const useDocumentBulkPCF = () => {
  const documentBulkPCF = usePostDocumentBulkPCF();

  return useMutation({
    mutationFn: documentBulkPCF,
  });
};

export const useDocumentComparisonPCF = () => {
  const documentComparisonPCF = usePostDocumentComparisonPCF();

  return useMutation({
    mutationFn: documentComparisonPCF,
  });
};
