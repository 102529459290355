import { useCallback } from "react";
import { Product } from "../../api/types";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";

export const useGetProductLink = () => {
  const getLinkWithParams = useGetLinkWithParams();

  const getLink = useCallback(
    ({ product }: { product: Product }) => {
      if (product.status !== "incomplete") {
        return `/products/${product.id}`;
      }

      if (
        !product.tech_specs ||
        (product.tech_specs instanceof Array && product.tech_specs.length === 0)
      )
        return getLinkWithParams("/edit/product-specs", { selectedProduct: product.id });

      if (!product.production_process_id) {
        return getLinkWithParams("/edit/product-production-process", {
          selectedProduct: product.id,
        });
      }

      if (!CATEGORIES_WITHOUT_RECIPE.includes(product.category.id)) {
        if (!product.recipe || (product.recipe instanceof Array && product.recipe.length === 0)) {
          return getLinkWithParams("/edit/product-recipe", { selectedProduct: product.id });
        }
      }

      return getLinkWithParams("/edit/product-details", { selectedProduct: product.id });
    },
    [getLinkWithParams],
  );

  return getLink;
};
