import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { scope } from "./auth";

const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
const audience = import.meta.env.VITE_AUTH0_AUDIENCE;

export function Auth0ProviderWithNavigate({ children }: { children: ReactNode }) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!domain || !clientId) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope,
      }}
      useRefreshTokens={false}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <SentryUserHandler>{children}</SentryUserHandler>
    </Auth0Provider>
  );
}

const SentryUserHandler = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();

  useEffect(() => {
    Sentry.setUser(user ? { email: user.email } : null);
  }, [user]);

  return children;
};
