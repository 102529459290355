import { useTranslation } from "react-i18next";
// import { useBlocker } from "react-router-dom";
import useBeforeUnload from "react-use/lib/useBeforeUnload";

export const useWarnBeforeUnload = (isDirty?: boolean) => {
  const { t } = useTranslation();

  // TODO: eventually add useBlocker to also warn before navigating away.
  //       We can't use this yet, because we'd have multiple useBlocker hooks on the same page,
  //       and that's not supported yet:
  // https://github.com/remix-run/react-router/discussions/9978
  // https://github.com/remix-run/react-router/pull/10780
  // useBlocker(!!isDirty);

  useBeforeUnload(isDirty, t("You have unsaved changes. Are you sure you want to leave?"));
};
