import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BulkPcfDocuments, Product } from "../../api/types";
import { SidePanel } from "../../components/SidePanel";
import { Label12, Label16 } from "../../components/Typography";
import { useDocumentBulkPCF } from "../../state/products";
import { ViewOrDownloadDocument } from "../ViewOrDownloadDocument";

export const DocumentsSidePanel = ({
  selectedProducts,
  open,
  onClose,
}: {
  selectedProducts: Set<Product>;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: documentBulkPCF, isPending } = useDocumentBulkPCF();

  const [documents, setDocuments] = useState<BulkPcfDocuments | null>(null);

  useEffect(() => {
    const createBulkPcf = async () => {
      setDocuments(null);
      const docs = await documentBulkPCF({
        manufacturerId: [...selectedProducts][0].manufacturer_id,
        plantId: [...selectedProducts][0].plant_id,
        productIds: [...selectedProducts].map((p) => p.id),
      });
      setDocuments(docs);
    };

    if (open) createBulkPcf();
  }, [documentBulkPCF, open, selectedProducts]);

  return (
    <SidePanel title={t("Documents")} open={open} onClose={onClose}>
      <div className="pt-4 space-y-2">
        <Label12>{t("Documents")}</Label12>
        <div className="flex flex-col">
          <div className="grid grid-cols-[1fr_auto] items-center gap-2 min-h-12 hover:bg-neutral-100 -mx-4 px-4">
            <Label16>{t("PCF Overview (PDF)")}</Label16>
            {isPending && (
              <div className="flex justify-center items-center h-full">
                <CircularProgress size="24px" />
              </div>
            )}
            {!isPending && documents && <ViewOrDownloadDocument doc={documents.pdf} />}
          </div>
          <div className="grid grid-cols-[1fr_auto] items-center gap-2 min-h-12 hover:bg-neutral-100 -mx-4 px-4">
            <Label16>{t("PCF Overview (Excel)")}</Label16>
            {isPending && (
              <div className="flex justify-center items-center h-full">
                <CircularProgress size="24px" />
              </div>
            )}
            {!isPending && documents && <ViewOrDownloadDocument doc={documents.xlsx} />}
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
