import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Impact, LciaResults } from "../../api/types";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Heading3, Label12, Text14 } from "../../components/Typography";
import { useImpactByIndicator } from "../../lib/impact";
import { exists } from "../../util/commonUtil";
import { formatUnit } from "../../util/format";

export const ProductStats = ({
  lciaResults,
  baseUnit,
}: {
  lciaResults: LciaResults;
  baseUnit: string;
}) => {
  const { t } = useTranslation();

  const embodiedCarbon = useImpactByIndicator(lciaResults.impact_summary.a1_a3, "GWP-total");
  const embodiedCarbonNet = useImpactByIndicator(lciaResults.impact_summary.a1_a3, "GWP-total-net");
  const pert = useImpactByIndicator(lciaResults.impact_summary.a1_a3, "PERT");
  const penrt = useImpactByIndicator(lciaResults.impact_summary.a1_a3, "PENRT");
  const energyUseValue = pert.value + penrt.value;

  const waterUse = useImpactByIndicator(lciaResults.impact_summary.a1_a3, "FW");

  if (waterUse.unit !== "m3") console.warn("Unexpected unit for FW");

  const statsItems: {
    indicator: string;
    value: number;
    unit: string;
    description: string;
    detailIndicator?: Impact;
    tooltip?: string;
  }[] = useMemo(() => {
    const waterUseLiter = {
      ...waterUse,
      value: waterUse.value * 1000,
      unit: "liters",
    };

    return [
      {
        ...embodiedCarbon,
        indicator: t("Embodied Carbon"),
        detailIndicator: embodiedCarbonNet,
        tooltip: t(
          "GWP-total is the sum of three indicators that differentiate greenhouse gases depending on their origin: (1) from transformation or decay of fossil fuels or fossil carbon-containing materials (GWP-fossil), (2) from sequestration in biomass (GWP-biogenic), and (3) from changes in the carbon stock due to land use and land use change associated with the declared unit (GWP-luluc).",
        ),
        description: t("This is the carbon footprint of this product."),
      },
      {
        indicator: t("Energy use"),
        value: energyUseValue,
        unit: "MJ",
        description: t("The total amount of energy from renewable and non-renewable sources."),
      },
      {
        ...waterUseLiter,
        indicator: t("Water use"),
        description: t("The total amount of water used for manufacturing this product."),
      },
    ];
  }, [embodiedCarbon, energyUseValue, waterUse, t, embodiedCarbonNet]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x divide-neutral-300">
      {statsItems.map((item) => (
        <div key={item.indicator} className="flex flex-col justify-start gap-3 px-6 md:first:pl-0">
          <div className="flex flex-col justify-start gap-1">
            <div className="flex items-center gap-2">
              <Label12>{item.indicator}</Label12>
              {item.tooltip && <InfoTooltip>{item.tooltip}</InfoTooltip>}
            </div>
            <div className="flex items-center gap-2">
              <Heading3>
                {item.value?.toPrecision(4)} {formatUnit(item.unit)}/{formatUnit(baseUnit)}
              </Heading3>
              {item.detailIndicator &&
                exists(item.detailIndicator.value) &&
                item.detailIndicator.value !== item.value && (
                  <Text14>
                    | {item.detailIndicator.value?.toPrecision(4)}{" "}
                    {formatUnit(item.detailIndicator.unit)}/{formatUnit(baseUnit)}
                  </Text14>
                )}
            </div>
          </div>
          <Text14>{item.description}</Text14>
        </div>
      ))}
    </div>
  );
};
