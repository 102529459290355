import { Clear, FactoryOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ManagePlantFields } from "../page-components/company-settings/ManagePlantFields";
import { usePlantForm } from "../page-components/plant-settings/usePlantForm";

export const AddPlant = ({
  onCreateSuccess,
  disableCancel,
}: {
  onCreateSuccess?: () => void;
  disableCancel?: boolean;
}) => {
  const { t } = useTranslation();
  const { methods, loading, onSubmit } = usePlantForm({ isEdit: false, onCreateSuccess });
  const navigate = useNavigate();

  const submitButton = (
    <Button type="submit" isDisabled={loading}>
      {loading && <CircularProgress size="24px" />}
      {t("Add plant")}
    </Button>
  );

  return (
    <FormProvider {...methods}>
      <PageContainer
        $as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        className="w-full"
      >
        <TopBar
          icon={<FactoryOutlined />}
          title={t("Add plant")}
          input={
            disableCancel ? (
              submitButton
            ) : (
              <Button
                type="button"
                intent="tertiaryFlat"
                onPress={() => navigate("/company-settings")}
              >
                {t("Cancel")}
                <Clear />
              </Button>
            )
          }
          hideNavigation
        />
        <div className="flex-grow flex flex-col gap-8 py-8 px-8">
          <Suspense>
            <ManagePlantFields />
          </Suspense>
        </div>
        {!disableCancel && (
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
            <Button type="button" intent="secondary" onPress={() => navigate("/company-settings")}>
              {t("Cancel")}
            </Button>
            {submitButton}
          </div>
        )}
      </PageContainer>
    </FormProvider>
  );
};
