import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Product categories
export const HARD_CODED_CONCRETE_CATEGORY_ID = "6a68b08b-786a-46f1-ac83-8eab240dd96c";
export const HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID = "fc7b3c1d-00fe-40cb-8455-b9c0bd4e326e";
export const HARD_CODED_WATERPROOFING_BITUMEN_CATEGORY_ID = "f7f1ca4f-dad7-4808-8773-74494ddb1d0e";
const HARD_CODED_AGGREGATES_ID = "a08eff44-36d5-4822-96d6-c9c59629f78b";

export const CATEGORIES_WITHOUT_RECIPE = [HARD_CODED_AGGREGATES_ID];

// Elementaries
export const HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID = "7d5fdd50-47d7-4334-b2f4-9f3e20c54c0e";

export const HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS = [
  "ISO 9001",
  "ISO 14001",
  "ISO 45001",
  "ISO 50001",
  "ISO 27001",
  "ISO 37001",
  "ISO 45003",
  "BES 6001",
];

// -------------------
// TODO: move to backend or to some place where we define product categories
export const useHardCodedConcreteMustContainValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t("Cement (or substitutes)"),
        fromCategories: ["cement", "ggbs", "fly ash", "silica fume powder"],
      },
      {
        label: t("Aggregates"),
        fromCategories: [
          "gravel",
          "aggregates",
          "natural aggregates",
          "sand",
          "limestone",
          "recycled concrete",
          "recycled brick",
          "recycled glass",
          "rock dust",
          "natural stone",
          "sand and gravel",
        ],
      },
      {
        label: t("Water"),
        fromCategories: ["water"],
      },
    ],
    [t],
  );
};
// -------------------
