import { AddOutlined, GridViewOutlined, ViewListOutlined } from "@mui/icons-material";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import { TabSwitch } from "../../components/TabSwitch";
import { UploadMaterialModal } from "./UploadProducts";

export const TopBarInput = ({
  selectedProducts,
  isSelectMode,
  onCreateDocuments,
  activeTab,
  setActiveTab,
}: {
  selectedProducts: Set<Product>;
  isSelectMode: boolean;
  onCreateDocuments: () => void;
  activeTab?: string;
  setActiveTab: (tab: string) => void;
}) => {
  const { t } = useTranslation();

  if (isSelectMode) {
    return (
      <Button
        intent="secondary"
        isDisabled={
          selectedProducts.size === 0 ||
          // We don't support more than 14 product in the bulk PCF,
          // because then the summary page breaks into mulitple pages
          selectedProducts.size > 14 ||
          [...selectedProducts].some((p) => p.status === "incomplete")
        }
        onPress={onCreateDocuments}
      >
        {t("Create New Documents")}
      </Button>
    );
  }

  return (
    <>
      <TabSwitch
        activeTab={activeTab ?? "grid"}
        setActiveTab={setActiveTab}
        options={[
          { label: <GridViewOutlined />, value: "grid" },
          { label: <ViewListOutlined />, value: "list" },
        ]}
      />
      <DialogTrigger>
        <Button intent="secondary">{t("Upload Products")}</Button>
        <UploadMaterialModal />
      </DialogTrigger>
      <Link href="/edit/product-details">
        <AddOutlined />
        {t("New Product")}
      </Link>
    </>
  );
};
