import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import type { Product } from "../../api/types";
import { Heading4, Text16 } from "../../components/Typography";
import { ProductStatusIndicator } from "./ProductStatusIndicator";
import { useGetProductLink } from "./useProductLink";

const CardContainer = tw(Link)<{ $isSelectMode?: boolean; $isSelected?: boolean }>`
  ${({ $isSelected, $isSelectMode }) =>
    $isSelectMode
      ? $isSelected
        ? "border-2 border-steelblue"
        : "border-2 border-dashed border-neutral-300 hover:border-solid hover:border-builtgreen"
      : "border border-neutral-300 hover:border-energyyellow"}

  rounded-2xl
  p-6

  shadow-e-xs hover:shadow-e-sm active:shadow-e-xs
  translate-y-0 hover:translate-y-[-1px] active:translate-y-0
  transition-all

  cursor-pointer

  flex
  flex-col
  justify-between
  gap-3
  bg-white
  h-[220px]
  overflow-hidden
`;

export const ProductCard = ({
  item,
  isSelected,
  isSelectMode,
  onToggleSelect,
}: {
  item: Product;
  isSelected?: boolean;
  isSelectMode?: boolean;
  onToggleSelect: () => void;
}) => {
  const getProductLink = useGetProductLink();
  return (
    <>
      <CardContainer
        $as={isSelectMode ? "div" : Link}
        to={getProductLink({ product: item })}
        unstable_viewTransition
        className="group relative"
        $isSelectMode={isSelectMode}
        $isSelected={isSelected}
        onClick={isSelectMode ? onToggleSelect : undefined}
        style={{
          viewTransitionName: `product-${item.id}`,
        }}
      >
        <ProductStatusIndicator status={item.status} />
        {isSelectMode && isSelected && (
          <CheckCircle className="absolute top-6 right-6 text-steelblue" fontSize="small" />
        )}
        <div className="flex flex-col gap-1">
          <Heading4 className="break-words">{item.name}</Heading4>
          <Text16 className="text-neutral-500">{item.category.name.en}</Text16>
        </div>
      </CardContainer>
    </>
  );
};
