import { useCallback } from "react";
import { EndOfScale, ProductCategory } from "../types";
import { useApi } from "../useApi";

export const useGetProductCategories = () => {
  const api = useApi<ProductCategory[]>();

  return useCallback(() => api({ url: `/v1/product-categories` }), [api]);
};

export const useGetEndsOfScale = () => {
  const api = useApi<EndOfScale[]>();

  return useCallback(
    (id: ProductCategory["id"]) =>
      api({ url: `/v1/product-categories/${id}/indicators-ends-of-scale` }),
    [api],
  );
};
