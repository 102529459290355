import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Plant } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Checkbox, CheckboxGroupField } from "../../components/CheckboxGroupField";
import { Label12 } from "../../components/Typography";
import { useProductCategories } from "../../state/productCategories";

// This is essentially just a custom ComboBoxMultiFieldConnected
//
// <ComboBoxMultiFieldConnected
//   name="product_categories"
//   options={options}
//   label={t("what do you produce in this plant?")}
//   wrapValueAt={5}
//   isrequired
// />

export const CustomProductCategoryPicker = ({ activePlant }: { activePlant?: Plant }) => {
  const {
    data: { productCategories },
  } = useProductCategories();
  const { watch, setValue, setError, clearErrors, formState } = useFormContext();
  const { t } = useTranslation();
  const selectedCategories: string[] = watch("product_categories");
  const [hasError, setHasError] = useState(false);

  const productionProcessCategoryIds = useMemo(
    () => new Set(activePlant?.production_processes.map((p) => p.category_id)),
    [activePlant],
  );

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setError("product_categories", { type: "custom", message: t("Required") });
      setHasError(true);
    } else {
      clearErrors("product_categories");
      setHasError(false);
    }
  }, [selectedCategories, setError, clearErrors, t]);

  const groupedCategories = useMemo(() => {
    return Object.groupBy(productCategories, (cat) => cat.group.en);
  }, [productCategories]);

  return (
    <div className="space-y-6">
      <div>
        <Label12 className="text-neutral-400">{t("What do you produce in this plant?")}</Label12>
        <hr className="border-neutral-200 mt-4" />
        {hasError && formState.submitCount > 0 && (
          <span className="text-red-500 text-sm font-bold">
            {t("At least one product category required")}
          </span>
        )}
      </div>
      <div
        className="grid gap-6"
        style={{
          gridTemplateColumns: `repeat(${Object.entries(groupedCategories).length}, 1fr)`,
        }}
      >
        {Object.entries(groupedCategories).map(([category, items]) => (
          <CheckboxGroupField
            key={category}
            value={selectedCategories.filter((cat) => items?.map((i) => i.id).includes(cat))}
            onChange={(value) => {
              setValue(
                "product_categories",
                Array.from(
                  new Set([
                    ...selectedCategories.filter((cat) => !items?.map((i) => i.id).includes(cat)),
                    ...value,
                  ]),
                ),
              );
            }}
            label={category}
            labelProps={{ style: { color: "var(--builtgreen)" } }}
          >
            {items!.map((category) => {
              // TODO: might be required soon
              const disabled = false;

              return (
                <Checkbox
                  key={category.id}
                  value={category.id}
                  isDisabled={productionProcessCategoryIds.has(category.id)}
                >
                  {category.name.en}
                  {disabled ? ` (${t("coming soon")})` : ""}
                </Checkbox>
              );
            })}
          </CheckboxGroupField>
        ))}
      </div>
      {productionProcessCategoryIds.size > 0 && (
        <div className="justify-self-end">
          <Alert intent="info">
            {t("Note: categories with a production process may not be deselected")}
          </Alert>
        </div>
      )}
    </div>
  );
};
