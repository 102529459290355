import { Decimal } from "decimal.js";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Elementary, PlantMaterial, Product } from "../../api/types";
import { Label } from "../../components/Typography";
import {
  combineIds,
  ProductRecipeFields,
  splitIds,
} from "../../page-components/product-recipe/ProductRecipeFields";
import {
  ProductRecipeForm,
  ProductRecipeMaterial,
  ProductRecipeMaterialOption,
} from "../../page-components/product-recipe/types";
import {
  HARD_CODED_CONCRETE_CATEGORY_ID,
  useHardCodedConcreteMustContainValidation,
} from "../../state/HARD_CODED";
import { useElementaries } from "../../state/elementaries";
import { useMaterials } from "../../state/materials";
import { useProduct, useSelectedProductId, useUpdateProduct } from "../../state/products";
import { useSplash } from "../../state/splash";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";

const sumByCategories = (
  elementariesMap: Record<string, Elementary>,
  categories: string[],
  materials: ProductRecipeMaterial[],
): Decimal => {
  return materials
    .filter((material) =>
      categories.includes(elementariesMap[material.elementaryId].category.toLowerCase()),
    )
    .map((material) => new Decimal(material.value || 0))
    .reduce((sum, val) => sum.plus(val), new Decimal(0));
};

const useErrorMessage = ({
  values,
  elementariesMap,
  selectedProduct,
}: {
  values: ProductRecipeForm;
  elementariesMap: Record<string, Elementary>;
  selectedProduct?: Product;
}) => {
  const { t } = useTranslation();
  const valueIsValid = (value?: unknown) => {
    // value is a string due to the TextField component
    // ideally: value should be a number right away in the form state
    return exists(value) && !isNaN(Number(value)) && Number(value) > 0;
  };

  const totalMass = useMemo(() => {
    if (!values.materials) return new Decimal(0);

    return values.materials
      .map((material) => new Decimal(material.value || 0))
      .reduce((sum, val) => sum.plus(val), new Decimal(0));
  }, [values]);

  const concreteMustContainValidation = useHardCodedConcreteMustContainValidation();

  return useMemo(() => {
    if (!selectedProduct?.mass) {
      return "Initializing ..";
    }

    if (selectedProduct.category.id === HARD_CODED_CONCRETE_CATEGORY_ID) {
      for (const ingredient of concreteMustContainValidation) {
        const categoryMass = sumByCategories(
          elementariesMap,
          ingredient.fromCategories,
          values.materials,
        );

        if (categoryMass.equals(0)) {
          return t("Recipe must contain {{ label }}.", { label: ingredient.label });
        }
      }
    }

    if (
      !(
        values.materials.length > 0 &&
        values.materials.every(({ value }) => valueIsValid(value)) &&
        values.materials.every((x) => exists(x.input))
      )
    ) {
      return t("All materials and masses must be filled.");
    }

    if (new Decimal(selectedProduct.mass).greaterThan(totalMass)) {
      return t(
        "Mass of ingredients ({{ recipeMass }} kg) is lower than mass of the product ({{ productMass }} kg).",
        {
          productMass: selectedProduct.mass.toString(),
          recipeMass: totalMass,
        },
      );
    }

    return null;
  }, [t, values, elementariesMap, totalMass, selectedProduct, concreteMustContainValidation]);
};

const useMaterialOptions = ({
  selectedProduct,
  rawMaterials,
}: {
  selectedProduct?: Product;
  rawMaterials: PlantMaterial[];
}) => {
  const { elementaries } = useElementaries();

  function compare(a: ProductRecipeMaterialOption, b: ProductRecipeMaterialOption) {
    if (a.isAllowedInCategory === b.isAllowedInCategory) {
      return a.label.localeCompare(b.label);
    } else if (a.isAllowedInCategory) {
      return -1;
    } else return 1;
  }

  const data: ProductRecipeMaterialOption[] = useMemo(() => {
    if (!elementaries || !selectedProduct) return [];

    return elementaries
      .filter((elementary) =>
        rawMaterials.some(
          (material) =>
            material.supplier_product?.elementary_id === elementary.id ||
            material.prechain_product?.product?.elementary_id === elementary.id,
        ),
      )
      .map((elementary) => ({
        elementaryId: elementary.id,
        label: elementary.name_en,
        unit: "kg",
        isAllowedInCategory: elementary.product_categories.includes(selectedProduct.category.id),
      }))
      .sort(compare);
  }, [elementaries, selectedProduct, rawMaterials]);

  return data;
};

const useSubmitRecipe = ({ selectedProduct }: { selectedProduct?: Product }) => {
  const { t } = useTranslation();
  const { setSplash } = useSplash();
  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ProductRecipeForm> = useCallback(
    (fields) => {
      if (!selectedProduct) return;

      updateProduct(
        {
          productId: selectedProduct.id,
          product: {
            ...selectedProduct,
            recipe: fields.materials.map(({ elementaryId, value, input }) => {
              const { supplierProductId, prechainProductId } = splitIds(input);

              return {
                product_id: selectedProduct.id,
                elementary_id: elementaryId,
                mass: Number(value),
                supplier_product_id: supplierProductId ?? null,
                prechain_product_id: prechainProductId ?? null,
              };
            }),
          },
        },
        {
          onSuccess: () => {
            navigate({ pathname: `/products/${selectedProduct.id}` });
            if (selectedProduct.status === "incomplete") {
              setSplash({ message: t("New product created") });
            }
          },
        },
      );
    },
    [navigate, selectedProduct, setSplash, t, updateProduct],
  );

  return {
    loading,
    onSubmit,
  };
};

const ProductRecipeMain = ({ id }: { id: string }) => {
  const { data: selectedProduct } = useProduct(id);

  return <ProductRecipeBase selectedProduct={selectedProduct} />;
};

export const ProductRecipe = () => {
  const id = useSelectedProductId();

  if (!id) return null;

  return <ProductRecipeMain id={id} />;
};

const ProductRecipeBase = ({ selectedProduct }: { selectedProduct: Product }) => {
  const { t } = useTranslation();

  const { elementariesMap } = useElementaries();

  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-production-process"));

  const { rawMaterials } = useMaterials();
  const materialOptions = useMaterialOptions({ selectedProduct, rawMaterials });

  const methods = useForm<ProductRecipeForm>({
    defaultValues: {
      materials: selectedProduct.recipe.map((item) => {
        return {
          elementaryId: item.elementary_id,
          label: elementariesMap[item.elementary_id]?.name_en,
          unit: "kg",
          value: item.mass,
          input: `${item.supplier_product_id}--${item.prechain_product_id}`,
        };
      }),
    },
  });

  useWarnBeforeUnload(methods.formState.isDirty);

  const values = methods.watch();

  const errorMessage = useErrorMessage({ values, elementariesMap, selectedProduct });

  const canGoNext = values.materials.length > 0 && !errorMessage;

  const reset = methods.reset;

  useEffect(
    function populateForm() {
      reset({
        materials: selectedProduct.recipe.map((item) => {
          return {
            elementaryId: item.elementary_id,
            label: elementariesMap[item.elementary_id]?.name_en,
            unit: "kg",
            value: item.mass,
            input: combineIds({
              supplierProductId: item.supplier_product_id,
              prechainProductId: item.prechain_product_id,
            }),
          };
        }),
      });
    },
    [reset, materialOptions, elementariesMap, selectedProduct, rawMaterials],
  );

  const { loading, onSubmit } = useSubmitRecipe({
    selectedProduct,
  });

  return (
    <FormProvider {...methods}>
      <form
        className="flex-grow flex flex-col gap-5 overflow-hidden mx-auto w-full max-w-6xl pb-20"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProductRecipeFields materialOptions={materialOptions} materials={rawMaterials} />
        <Label className="text-gray-500 self-end">
          {errorMessage ||
            t("By continuing, I confirm the correctness and completeness of my input")}
        </Label>
        <EditFlowNav onPrev={onPrev} nextSubmit nextDisabled={!canGoNext} nextLoading={loading} />
      </form>
    </FormProvider>
  );
};
