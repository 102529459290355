import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

// Since we're storing some app state in the URL as search parameters
// we need a way to use react-router's <Link> component and preserve
// the search parameters. This hook will take a path and an optional
// object of params and return a string that can be used as the "to"
// prop of a <Link> component.
export const useGetLinkWithParams = () => {
  const [searchParams] = useSearchParams();

  return useCallback(
    (to: string, params?: Record<string, string>) => {
      const search = new URLSearchParams(searchParams);

      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          search.set(key, value);
        });
      }

      return `${to}?${search.toString()}`;
    },
    [searchParams],
  );
};
