import { CloseOutlined } from "@mui/icons-material";
import { animated, useSpring } from "@react-spring/web";
import { ReactNode, useRef } from "react";
import useClickAway from "react-use/lib/useClickAway";
import { Button } from "./Button";
import { Heading3 } from "./Typography";

/**
 * A side panel that slides in from the right, animated
 */
export const SidePanel = ({
  open,
  onClose,
  title,
  children,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    onClose();
  });

  const slideIn = useSpring({
    transform: open ? "translateX(0%)" : "translateX(100%)",
    config: {
      tension: 500,
      friction: 40,
    },
  });

  return (
    <animated.div
      ref={ref}
      style={slideIn}
      className="fixed z-20 right-0 top-0 h-full bg-white w-[500px] drop-shadow-md overflow-hidden flex flex-col"
    >
      <div className="h-20 px-4 flex items-center justify-between bg-neutral-100">
        <Heading3>{title}</Heading3>
        <Button intent="tertiaryFlat" square onPress={onClose}>
          <CloseOutlined />
        </Button>
      </div>
      <hr className="border-t border-neutral-300 m-0" />
      <div className="px-4 pt-4 pb-8 overflow-y-auto flex-grow">{children}</div>
    </animated.div>
  );
};
